<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    table_data: Array,
    table_labels: Array,
  },
  data: () => ({
    chart_labels: [],
    chart_data: [],
    options: {
      maintainAspectRatio: false,
      responsive: false,
      layout: {
        padding: 10,
      },
    },
  }),
  created() {
    this.table_data.forEach((el) => {
      this.chart_labels.push(el.Catagorie);
      if (el.Afschrijving.length <= 3) {
        this.chart_data.push(`0.${el.Afschrijving}`);
      } else {
        this.chart_data.push(el.Afschrijving.replace(",", "."));
      }
    });

    this.ChartData = {
      labels: this.chart_labels,
      datasets: [
        {
          label: "Afschrijvingen",
          backgroundColor: "#f87979",
          data: this.chart_data,
        },
      ],
    };
  },
  mounted() {
    this.renderChart(this.ChartData, this.options);
  },
};
</script>
