<template>
  <table :class="`table--perweek table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <tr>
        <th class="table__head--text">Soort</th>
        <th class="table__head--text table__data--right">Aantal</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Waarde</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Vraagprijs</th>
        <th class="table__head--text table__data--right">Stadagen</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, key) in table_data"
        :key="key"
        :class="`table__row--color`"
      >
        <td class="table__cell--default">
          <router-link
            :to="{
              name: 'voorraadstanddetails',
              params: { bu: table_style, title: item.Soort },
            }"
            >{{ item.Soort }}</router-link
          >
        </td>
        <td class="table__data--right table__cell--default">{{ item.Aantal }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.Waarde }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.vraagprijs }}</td>
        <td class="table__data--right table__cell--default">{{ item.Stadagen }}</td>
      </tr>
    </tbody>
    <tfoot class="table__foot">
      <th class="table__foot--padding">Totaal:</th>
      <th class="table__foot--padding table__data--right">{{ table_sum_data.aantal }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__foot--padding table__data--right">€ {{ table_sum_data.waarde }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__foot--padding table__data--right">€ {{ table_sum_data.vraagprijs }}</th>
      <th class="table__foot--padding table__data--right">{{ table_sum_data.stadagen }}</th>
    </tfoot>
  </table>
</template>
<script>


export default {
  props: {
    table_data: Array,
    table_style: String,
    table_sum_data: Object,
  },

};
</script>
