<template>
  <table :class="`w-100 table--default table__border--${table_style}`">
    <thead :class="`table__head--${table_style}`">
      <tr>
        <th class="table__head--text">Vlag</th>
        <th class="table__head--text">Naam</th>
        <th class="table__head--text">Aantal</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Waarde</th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__head--text table__data--right">Vraagprijs</th>
        <th class="table__head--text table__data--right">Stadagen</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, key) in table_data"
        :key="key"
        :class="`table__row--color`"
      >
        <td class="table__cell--default text-center"><Flag v-if="item.code != 'GB'" :code="item.code" /> <Flag v-else :code="`GB-UKM`" /></td>
        <td class="table__cell--default">{{ item.naam }}</td>
        <td class="table__cell--default">{{ item.aantal }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.waarde }}</td>
        <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.vraagprijs }}</td>
        <td class="table__data--right table__cell--default">{{ item.stadagen }}</td>
      </tr>
    </tbody>
    <tfoot class="table__foot">
      <th class="table__foot--padding">Totaal:</th>
      <th class="table__foot--padding"></th>
      <th class="table__foot--padding">{{ table_data_sum.aantal }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__foot--padding">€ {{ table_data_sum.waarde }}</th>
      <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__foot--padding">€ {{ table_data_sum.vraagprijs }}</th>
      <th class="table__data--right table__foot--padding">{{ table_data_sum.stadagen }}</th>
    </tfoot>
  </table>
</template>

<script>


export default {
  props: {
    table_data: Array,
    table_data_sum: Object,
    table_style: String,
  },

};
</script>