<template>
  <div class="d-flex flex-row">
    <div class="flex-grow-1">
      <table :class="`w-100 table--default table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
          <tr>
            <th class="table__head--text">Catagorie</th>
            <th class="table__head--text">%</th>
            <th class="table__data--right table__head--text">Aantal</th>
            <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__head--text">Waarde</th>
            <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__head--text">Vraagprijs</th>
            <th class="table__data--right table__head--text">Afschrijving</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, key) in table_data"
            :key="key"
            :class="`table__row--color`"
          >
            <td class="table__cell--default">
              <router-link
                :to="{
                  name: 'voorraadstandafschriftdetails',
                  params: {
                    bu: table_style,
                    group: item.Catagorie,
                    status: table_status,
                  },
                }"
                >{{ item.Catagorie }}</router-link
              >
            </td>
            <td class="table__cell--default">{{ item.Perc }}%</td>
            <td class="table__data--right table__cell--default">
              {{ item.Aantal }}
            </td>
            <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">
              € {{ item.Waarde }}
            </td>
            <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">
              € {{ item.vraagprijs }}
            </td>
            <td class="table__data--right table__cell--default">
              € {{ item.Afschrijving }}
            </td>
          </tr>
        </tbody>
        <tfoot class="table__foot">
          <tr>
            <th class="table__foot--padding">Totaal:</th>
            <th class="table__foot--padding"></th>
            <th class="table__data--right table__foot--padding">{{ table_data_sum.aantal }}</th>
            <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__foot--padding">€ {{ table_data_sum.waarde }}</th>
            <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__foot--padding">
              € {{ table_data_sum.vraagprijs }}
            </th>
            <th class="table__data--right table__foot--padding">
              € {{ table_data_sum.afschrijving }}
            </th>
          </tr>
        </tfoot>
      </table>
    </div>
    <div class="chart__box">
      <BarChart
        v-if="table_data"
        :table_data="table_data"
        :height="600"
        :width="600"
      />
    </div>
  </div>
</template>
<script>
import BarChart from "@/components/BarChart.vue";


export default {
  props: {
    table_data: Array,
    table_data_sum: [Object, Array],
    table_style: String,
    table_status: String,
  },
  components: { BarChart },

};
</script>
