<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <Loading v-if="loading" />
    <Tabs v-if="!loading">
      <Tab title="Status">
        <div class="flex _row spaceevenly">
          <div class="flex-grow">
            <h3 class="text-center">Eigen Voertuigen</h3>
            <StockPositionStatusTable :table_data="stock_position_status" :table_sum_data="stock_position_sum"
              :table_style="`${bu}`" :redirects="stock_status_redirects" />
          </div>
          <div class="flex-grow ml-3">
            <h3 class="text-center">Consignatie</h3>
            <StockPositionStatusTable :table_data="stock_position_status_con" :table_sum_data="stock_position_sum_con"
              :table_style="`${bu}`" :redirects="stock_status_con_redirects" />
          </div>
        </div>
      </Tab>
      <Tab title="Soort">
        <div class="flex _row spaceevenly">
          <div class="flex-grow mr-3">
            <h3 class="text-center">Eigen Voertuigen</h3>
            <StockPositionKindTable :table_data="stock_position_kind" :table_sum_data="stock_position_kind_sum"
              :table_style="`${bu}`" />
          </div>
          <div class="flex-grow ml-3">
            <h3 class="text-center">Consignatie</h3>
            <StockPositionKindTable :table_data="stock_position_kind_con" :table_sum_data="stock_position_kind_con_sum"
              :table_style="`${bu}`" />
          </div>
        </div>
      </Tab>
      <Tab title="Merk">
        <div class="flex _row spaceevenly">
          <div class="flex-grow mr-3">
            <h3 class="text-center">Eigen Voertuigen</h3>
            <StockPositionBrandTable :table_data="stock_position_brand" :table_sum_data="stock_position_brand_sum"
              :table_style="`${bu}`" />
          </div>
          <div class="flex-grow ml-3">
            <h3 class="text-center">Consignatie</h3>
            <StockPositionBrandTable :table_data="stock_position_brand_con" :table_sum_data="stock_position_brand_con_sum"
              :table_style="`${bu}`" />
          </div>
        </div>
      </Tab>
      <Tab title="Afschrijving totaal" lazy>
        <div class="flex _row ">
          <div class="w100 mr-3">
            <StockPositionWriteOffTable :table_data="stock_position_writeoff_total"
              :table_data_sum="stock_position_writeoff_total_sum" :table_style="`${bu}`" :table_status="status[0]" />
          </div>
        </div>
      </Tab>
      <Tab title="Afschrijving voorraad" lazy>
        <div>
          <button class="m2 employee_download" @click="createExcel(bu, status[1])">Uitdraai maken</button>
          <StockPositionWriteOffTable :table_data="stock_position_writeoff_stock"
            :table_data_sum="stock_position_writeoff_stock_sum" :table_style="`${bu}`" :table_status="status[1]" />
        </div>
      </Tab>
      <Tab title="Afschrijving verkocht" lazy>
        <div>
          <button class="m2 employee_download" @click="createExcel(bu, status[2])">Uitdraai maken</button>
          <StockPositionWriteOffTable :table_data="stock_position_writeoff_sold"
            :table_data_sum="stock_position_writeoff_sold_sum" :table_style="`${bu}`" :table_status="status[2]" />
        </div>
      </Tab>
      <Tab title="Target landen">
        <div>
          <StockPositionCountriesTable :table_data="stock_position_countries"
            :table_data_sum="stock_position_countries_sum" :table_style="`${bu}`" />
        </div>
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import StockPositionStatusTable from "@/components/StockPositionStatusTable.vue";
import StockPositionKindTable from "@/components/StockPositionKindTable.vue";
import StockPositionBrandTable from "@/components/StockPositionBrandTable.vue";
import StockPositionWriteOffTable from "@/components/StockPositionWriteOffTable.vue";
import StockPositionCountriesTable from "@/components/StockPositionCountriesTable.vue";
import Loading from "@/components/Loading.vue";
import request from "@/functions/request.js";
import Tabs from "@/components/Tabs/Tabs.vue";
import Tab from "@/components/Tabs/Tab.vue";
import * as XLSX from "xlsx";

export default {
  props: ["bu"],
  components: {
    StockPositionStatusTable,
    StockPositionKindTable,
    StockPositionBrandTable,
    StockPositionWriteOffTable,
    StockPositionCountriesTable,
    Tabs,
    Tab,
    Loading,
  },
  data: () => ({
    stock_position_status: null,
    stock_position_status_con: null,
    stock_position_sum: null,
    stock_position_sum_con: null,
    stock_position_kind: null,
    stock_position_kind_con: null,
    stock_position_kind_sum: null,
    stock_position_kind_con_sum: null,
    stock_position_brand: null,
    stock_position_brand_con: null,
    stock_position_brand_sum: null,
    stock_position_brand_con_sum: null,
    stock_position_writeoff_total: null,
    stock_position_writeoff_total_sum: null,
    stock_position_writeoff_stock: null,
    stock_position_writeoff_stock_sum: null,
    stock_position_writeoff_sold: null,
    stock_position_writeoff_sold_sum: null,
    stock_position_countries: null,
    stock_position_countries_sum: null,
    stock_position_writeoff_details_excel: null,
    stock_status_redirects: ["#", "voorraad", "Verkooporders", "Verkooporders"],
    stock_status_con_redirects: ["#", "voorraad", "Verkooporders"],
    status: ["all", "S", "V"],
    loading: true,
  }),
  created() {
    this.getData(this.bu);
  },
  watch: {
    bu(newbu) {
      this.loading = true;
      this.getData(newbu);
    },
  },
  methods: {
    getData(bu) {
      request(`stock-position-status/${bu}`, "GET").then(
        ({
          stock_position_status,
          stock_position_status_con,
          stock_position_sum,
          stock_position_con_sum,
        }) => {
          this.stock_position_status = stock_position_status;
          this.stock_position_status_con = stock_position_status_con;
          this.stock_position_sum = stock_position_sum;
          this.stock_position_sum_con = stock_position_con_sum;
        }
      );

      request(`stock-position-kind/${bu}`, "GET").then(
        ({
          stock_position_kind,
          stock_position_kind_con,
          stock_position_kind_sum,
          stock_position_kind_con_sum,
        }) => {
          this.stock_position_kind = stock_position_kind;
          this.stock_position_kind_con = stock_position_kind_con;
          this.stock_position_kind_sum = stock_position_kind_sum;
          this.stock_position_kind_con_sum = stock_position_kind_con_sum;
        }
      );

      request(`stock-position-brand/${bu}`, "GET").then(
        ({
          stock_position_brand,
          stock_position_brand_con,
          stock_position_brand_sum,
          stock_position_brand_con_sum,
        }) => {
          this.stock_position_brand = stock_position_brand;
          this.stock_position_brand_con = stock_position_brand_con;
          this.stock_position_brand_sum = stock_position_brand_sum;
          this.stock_position_brand_con_sum = stock_position_brand_con_sum;
        }
      );

      request(`stock-position-writeoff-total/${bu}`, "GET").then(
        ({
          stock_position_writeoff_total,
          stock_position_writeoff_total_sum,
        }) => {
          this.stock_position_writeoff_total = stock_position_writeoff_total;
          this.stock_position_writeoff_total_sum = stock_position_writeoff_total_sum;
        }
      );

      request(`stock-position-writeoff-stock/${bu}`, "GET").then(
        ({
          stock_position_writeoff_stock,
          stock_position_writeoff_stock_sum,
        }) => {
          this.stock_position_writeoff_stock = stock_position_writeoff_stock;
          this.stock_position_writeoff_stock_sum = stock_position_writeoff_stock_sum;
        }
      );

      request(`stock-position-writeoff-sold/${bu}`, "GET").then(
        ({
          stock_position_writeoff_sold,
          stock_position_writeoff_sold_sum,
        }) => {
          this.stock_position_writeoff_sold = stock_position_writeoff_sold;
          this.stock_position_writeoff_sold_sum = stock_position_writeoff_sold_sum;
        }
      );

      request(`stock-position-countries/${bu}`, "GET").then(
        ({ stock_position_countries, stock_position_countries_sum }) => {
          this.stock_position_countries = stock_position_countries;
          this.stock_position_countries_sum = stock_position_countries_sum;
          this.loading = false;
        }
      );
    },

    createExcel(bu, status) {
      request(`stock-position-writeoff-details-excel/${bu}/${status}`, "GET").then(
        ({ stock_position_writeoff_details_excel, }) => {
          this.stock_position_writeoff_details_excel = stock_position_writeoff_details_excel;

          this.write_off_items = [];

          if (stock_position_writeoff_details_excel) {
            stock_position_writeoff_details_excel.forEach((i) => {
              this.write_off_items.push({
                "Itemnummer": i.nummer,
                "Verkoopdatum": i.factuurdatum,
                "Percent": i.percentage + '%',
                "Waarde": Number(i.waarde.replace(/,/g, '')),
                "Vraagprijs": Number(i.vraagprijs.replace(/,/g, '')),
                "Netto verkoopprijs": Number(i.nettoprijs.replace(/,/g, '')),
                "Marge": Number(i.marge.replace(/,/g, '')),
                "Afschrijving": Number(i.Afschrijving.replace(/,/g, '')),
              });
            });
            console.log(this.write_off_items)

            var telWS = XLSX.utils.json_to_sheet(this.write_off_items);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(
              wb,
              telWS,
              `Afschrijvingen_${bu}_${status}_${this.moment().format("DD-MM-YYYY")}`
            );
            XLSX.writeFile(
              wb,
              `Afschrijvingen_${bu}_${status}_${this.moment().format("DD-MM-YYYY")}.xlsx`
            );
          }
        }
      );
    },
  },
};
</script>
