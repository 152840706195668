var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"flex-grow-1"},[_c('table',{class:`w-100 table--default table__border--${_vm.table_style}`},[_c('thead',{class:`table__head--${_vm.table_style}`},[_c('tr',[_c('th',{staticClass:"table__head--text"},[_vm._v("Catagorie")]),_c('th',{staticClass:"table__head--text"},[_vm._v("%")]),_c('th',{staticClass:"table__data--right table__head--text"},[_vm._v("Aantal")]),(_vm.checkroles(['marges_zien'], _vm.msalInstance.getAllAccounts()[0].idTokenClaims.roles))?_c('th',{staticClass:"table__data--right table__head--text"},[_vm._v("Waarde")]):_vm._e(),(_vm.checkroles(['marges_zien'], _vm.msalInstance.getAllAccounts()[0].idTokenClaims.roles))?_c('th',{staticClass:"table__data--right table__head--text"},[_vm._v("Vraagprijs")]):_vm._e(),_c('th',{staticClass:"table__data--right table__head--text"},[_vm._v("Afschrijving")])])]),_c('tbody',_vm._l((_vm.table_data),function(item,key){return _c('tr',{key:key,class:`table__row--color`},[_c('td',{staticClass:"table__cell--default"},[_c('router-link',{attrs:{"to":{
                name: 'voorraadstandafschriftdetails',
                params: {
                  bu: _vm.table_style,
                  group: item.Catagorie,
                  status: _vm.table_status,
                },
              }}},[_vm._v(_vm._s(item.Catagorie))])],1),_c('td',{staticClass:"table__cell--default"},[_vm._v(_vm._s(item.Perc)+"%")]),_c('td',{staticClass:"table__data--right table__cell--default"},[_vm._v(" "+_vm._s(item.Aantal)+" ")]),(_vm.checkroles(['marges_zien'], _vm.msalInstance.getAllAccounts()[0].idTokenClaims.roles))?_c('td',{staticClass:"table__data--right table__cell--default"},[_vm._v(" € "+_vm._s(item.Waarde)+" ")]):_vm._e(),(_vm.checkroles(['marges_zien'], _vm.msalInstance.getAllAccounts()[0].idTokenClaims.roles))?_c('td',{staticClass:"table__data--right table__cell--default"},[_vm._v(" € "+_vm._s(item.vraagprijs)+" ")]):_vm._e(),_c('td',{staticClass:"table__data--right table__cell--default"},[_vm._v(" € "+_vm._s(item.Afschrijving)+" ")])])}),0),_c('tfoot',{staticClass:"table__foot"},[_c('tr',[_c('th',{staticClass:"table__foot--padding"},[_vm._v("Totaal:")]),_c('th',{staticClass:"table__foot--padding"}),_c('th',{staticClass:"table__data--right table__foot--padding"},[_vm._v(_vm._s(_vm.table_data_sum.aantal))]),(_vm.checkroles(['marges_zien'], _vm.msalInstance.getAllAccounts()[0].idTokenClaims.roles))?_c('th',{staticClass:"table__data--right table__foot--padding"},[_vm._v("€ "+_vm._s(_vm.table_data_sum.waarde))]):_vm._e(),(_vm.checkroles(['marges_zien'], _vm.msalInstance.getAllAccounts()[0].idTokenClaims.roles))?_c('th',{staticClass:"table__data--right table__foot--padding"},[_vm._v(" € "+_vm._s(_vm.table_data_sum.vraagprijs)+" ")]):_vm._e(),_c('th',{staticClass:"table__data--right table__foot--padding"},[_vm._v(" € "+_vm._s(_vm.table_data_sum.afschrijving)+" ")])])])])]),_c('div',{staticClass:"chart__box"},[(_vm.table_data)?_c('BarChart',{attrs:{"table_data":_vm.table_data,"height":600,"width":600}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }